<template>
  <v-dialog v-model="localDialog" max-width="500px">
    <form-wizard
      :form-data="formData"
      :item="item"
      :namespace="namespace"
      :title="title"
      :go-to="goTo"
      @dialog-cancel="updateDialog(false)"
      :icon="icon"
      :store="store"
      :store-action="storeAction"
    ></form-wizard>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    goToUrl: {
      default: null,
      required: false
    }
  },
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog-update", value);
      }
    },
    icon() {
      return "mdi-file";
    },
    storeAction() {
      return "files/uploadFile";
    },
    store() {
      return "files";
    },
    title() {
      return "Attach file";
    },

    namespace() {
      return "file";
    },
    item() {
      return {
        preview_url: ""
      };
    },
    goTo() {
      if (this.goToUrl) {
        return this.goToUrl;
      }
      return this.$router.currentRoute.path;
    },
    formData() {
      let form = [
        {
          text: "File*",
          value: "url",
          flex: "col-sm-12 col-md-12",
          required: "required",
          fileUpload: true,
          class: "",
          validate: ""
        }
      ];
      return form;
    }
  },
  data() {
    return {};
  },
  methods: {
    updateDialog(dialog) {
      this.localDialog = dialog;
    },
    cancelDialog() {
      this.$emit("dialog-cancel", true);
    }
  }
};
</script>
